
import CompanyRelations from "~/graphql/Person/CompanyRelations.gql";
import CompanyLink from "~/components/EntityLink/components/CompanyLink.vue";
import { getRoleString } from "~/helpers/person-helpers.js";

export default {
  components: {
    CompanyLink,
  },
  inheritAttrs: false,

  apollo: {
    person: {
      query: CompanyRelations,

      update(data) {
        return data.person;
      },

      error(error) {
        console.error("Person failed:", error);
      },

      variables() {
        return {
          id: this.$route.params.id,
        };
      },
    },
  },

  computed: {
    loading: function () {
      return this.$apollo.queries.person.loading;
    },

    relations: function () {
      return (
        this.person?.relations?.map((re) => {
          return { name: re.name, cvr: re.cvrNumber, roles: re.roles };
        }) || []
      );
    },
  },

  methods: {
    getRolesString: function (roles) {
      return roles.map((r) => getRoleString(r)).join(", ");
    },
  },
};
